import React, { useState } from "react";
import logo from "../assets/logo.png"; // Adjust the path to your logo image
import instagramIcon from "../assets/instagram.svg";
import facebookIcon from "../assets/facebook.svg";
import phoneIcon from "../assets/phone.svg";
import hamburgerMenu from "../assets/hamburgerMenu.svg";
import closeIcon from "../assets/closeIcon.svg";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="py-4 px-6 lg:px-16 bg-white shadow-md flex items-center justify-between fixed top-0 w-full z-50">
      <div className="flex items-center w-full justify-between">
        {/* Desktop Navigation */}
        <div className="hidden lg:flex space-x-6">
          <a href="#about-us" className="text-base font-light">
            Sobre Nós
          </a>
          <a href="#benefits" className="text-base font-light">
            Vantagens
          </a>
          <a href="#services" className="text-base font-light">
            Os Nossos Serviços
          </a>
        </div>

        <img src={logo} alt="Logo" className="w-auto h-16 mx-0" />

        {/* Desktop Navigation */}
        <div className="hidden lg:flex space-x-6">
          <a
            href="https://www.instagram.com/mjs.credito"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-base font-light"
          >
            <img
              src={instagramIcon}
              alt="Instagram"
              className="w-3.5 h-3.5 mr-2.5"
            />
            Instagram
          </a>
          <a
            href="https://www.facebook.com/mjs.credito"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-base font-light"
          >
            <img
              src={facebookIcon}
              alt="Facebook"
              className="w-3.5 h-3.5 mr-2.5"
            />
            Facebook
          </a>
          <a
            href="tel:+351964207791"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-base font-light"
          >
            <img
              src={phoneIcon}
              alt="Facebook"
              className="w-3.5 h-3.5 mr-2.5"
            />
            +351 964 207 791
          </a>
        </div>
      </div>

      {/* Mobile and Tablet Hamburger Menu */}
      <div className={`lg:hidden ${menuOpen ? "fixed inset-0 bg-white" : ""}`}>
        <button onClick={toggleMenu}>
          <img src={hamburgerMenu} alt="" />
        </button>

        {menuOpen && (
          <div className="absolute top-0 left-0 w-full h-full bg-white py-4 px-6 shadow-md items-center flex flex-col">
            <div className="flex justify-end w-full pb-14">
            <button onClick={toggleMenu}>
              <img src={closeIcon} alt="" />
            </button>
            </div>          
            <a href="#about-us" onClick={toggleMenu} className="block py-2 text-xl font-light">
              Sobre Nós
            </a>
            <a href="#benefits" onClick={toggleMenu} className="block py-2 text-xl font-light">
              Vantagens
            </a>
            <a href="#services" onClick={toggleMenu} className="block py-2 text-xl font-light">
              Os Nossos Serviços
            </a>
            <a
            href="https://www.instagram.com/mjs.credito"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-xl font-light py-2"
          >
            <img
              src={instagramIcon}
              alt="Instagram"
              className="w-3.5 h-3.5 mr-2.5"
            />
            Instagram
          </a>
          <a
            href="https://www.facebook.com/mjs.credito"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-xl font-light py-2"
          >
            <img
              src={facebookIcon}
              alt="Facebook"
              className="w-3.5 h-3.5 mr-2.5"
            />
            Facebook
          </a>
          <a
            href="tel:+351964207791"
            target="_blank"
            rel="noopener noreferrer"
            className="flex items-center text-xl font-light py-2"
          >
            <img
              src={phoneIcon}
              alt="Facebook"
              className="w-3.5 h-3.5 mr-2.5"
            />
            +351 964 207 791
          </a>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
