import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import servicesImage from "../assets/services.png";

const Services = () => {
  const [refImage, inViewImage] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const imageVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  return (
    <section className="px-4 md:px-12 py-12 lg-py-0 overflow-hidden" id="services">
      <div className="container mx-auto flex flex-col lg:flex-row lg:items-center lg:justify-between gap-x-20 gap-y-10 overflow-hidden">
        {/* First Column */}
        <div className=" grid w-full lg:w-1/2 mb-4 lg:mb-0 gap-y-2.5">
          {/* Content for the first column */}
          <span className="uppercase text-base font-light">
            Os nossos serviços
          </span>
          <h2>
            Opções de Crédito à Medida: Habitação, Pessoal e Transferência
          </h2>
          <ul className="list-disc pl-6">
            <li className="text-base font-light">
              <b className="font-semibold">Crédito habitação:</b> Oferecemos
              opções de crédito habitação adaptadas às suas necessidades
              específicas, para que alcance o financiamento perfeito para a casa
              dos seus sonhos.
            </li>
            <li className="text-base font-light">
              <b className="font-semibold">Crédito pessoal:</b> Oferecemos
              opções de crédito pessoal adaptadas às suas necessidades
              específicas, para que alcance o financiamento perfeito para
              concretizar os seus projetos pessoais.
            </li>
            <li className="text-base font-light">
              <b className="font-semibold">Transferência de crédito:</b> Se já
              possui um crédito habitação, oferecemos soluções de transferência
              para melhorar as suas condições, garantindo uma gestão financeira
              mais vantajosa.
            </li>
          </ul>
        </div>

        {/* Second Column */}
        <motion.div
          ref={refImage}
          initial="hidden"
          animate={inViewImage ? "visible" : "hidden"}
          variants={imageVariants}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="w-full lg:w-1/2 order-first lg:order-last"
        >
          <img src={servicesImage} alt="Servicos" />
        </motion.div>
      </div>
    </section>
  );
};

export default Services;
