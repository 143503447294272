import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import backgroundImage from "../assets/hero.png";

const Hero = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const textVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section
      className="py-40 lg:py-0 lg:min-h-screen flex items-center justify-center bg-cover bg-center"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <motion.div
        ref={ref}
        initial="hidden"
        animate={inView ? "visible" : "hidden"}
        variants={textVariants}
        transition={{ duration: 1, ease: "easeInOut" }}
        className="text-center max-w-6xl px-4"
      >
        <h1 className="text-3xl lg:text-5xl font-semibold mb-2">Precisa de ajuda com o seu crédito habitação?</h1>
        <p className="text-base lg:text-lg">Com dedicação e conhecimento especializado, estamos prontos para ajudá-lo a encontrar a melhor opção de crédito habitação para o seu novo lar.</p>
      </motion.div>
    </section>
  );
};

export default Hero;
