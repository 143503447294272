import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import aboutImage from "../assets/about.png";

const About = () => {

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const variants = {
    hidden: { opacity: 0, x: -100 }, // Start from left (-100)
    visible: { opacity: 1, x: 0 },   // Move to original position (0)
  };

  return (
    <section className="px-4 md:px-12 py-12 lg-py-0 overflow-hidden" id="about-us">
      <div className="container mx-auto flex flex-col lg:flex-row lg:items-center lg:justify-between gap-x-20 gap-y-10 overflow-hidden">
        {/* First Column */}
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"} // Use variants for animation
          variants={variants}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="w-full lg:w-1/2"
        >
          <img src={aboutImage} alt="Sobre Nos" />
        </motion.div>

        {/* Second Column */}
        <div className=" grid w-full lg:w-1/2 mb-4 lg:mb-0 gap-y-2.5">
          {/* Content for the first column */}
          <span className="uppercase text-base font-light">Sobre nós</span>
          <h2>Descubra a Essência e a Paixão da Nossa Equipa</h2>
          <p>
            Dedicamo-nos a abrir portas para um futuro promissor, a nossa equipa
            é apaixonada por encontrar soluções personalizadas para tornar
            realidade o seu sonho de casa própria. Com conhecimento
            especializado no mercado de crédito habitação e um compromisso
            inabalável com a satisfação dos nossos clientes, procuramos superar
            expectativas a cada passo da jornada. Guiados pela missão de
            proporcionar-lhe segurança e confiança no processo de aquisição do
            seu novo lar, estamos prontos para enfrentar os desafios e trilhar
            consigo o caminho rumo à concretização dos seus objetivos
            imobiliários.
          </p>
        </div>
      </div>
    </section>
  );
};

export default About;
