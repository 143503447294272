import React from "react";
import facebookIcon from "../assets/facebook.svg";
import instagramIcon from "../assets/instagram.svg";
import phoneIcon from "../assets/phone.svg";
import emailIcon from "../assets/email.svg";

const Footer = () => {
  return (
    <footer className="py-12 px-4 md:px-12">
      <div className="container mx-auto flex flex-col gap-y-12">
        <div className="flex flex-col gap-y-5 md:flex-row md:gap-x-6">
          {/* First Row */}
          <div className="w-full md:w-1/3 mb-4 md:px-0 md:mb-0 flex flex-col gap-y-2.5">
            <h2 className="text-xl">Siga-nos nas redes sociais</h2>
            <a
              href="https://www.instagram.com/mjs.credito"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-sm font-light"
            >
              <img
                src={instagramIcon}
                alt="Instagram Icon"
                className="w-3.5 h-3.5 mr-2.5"
              />
              Instagram
            </a>
            <a
              href="https://www.facebook.com/mjs.credito"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-sm font-light"
            >
              <img
                src={facebookIcon}
                alt="Facebook Icon"
                className="w-3.5 h-3.5 mr-2.5"
              />
              Facebook
            </a>
          </div>
          <div className="w-full md:w-1/3 mb-4 md:px-0 md:mb-0 flex flex-col gap-y-2.5">
            <h2 className="text-xl">Contactos</h2>
            <a
              href="tel:+351964207791"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-sm font-light"
            >
              <img
                src={phoneIcon}
                alt="Facebook"
                className="w-3.5 h-3.5 mr-2.5"
              />
              +351 964 207 791
            </a>
            <a
              href="mailto:mjsampaio.icredito@gmail.com"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center text-sm font-light"
            >
              <img
                src={emailIcon}
                alt="Facebook"
                className="w-3.5 h-3.5 mr-2.5"
              />
              mjsampaio.icredito@gmail.com
            </a>
          </div>
          <div className="w-full md:w-1/3  mb-4 md:px-0 md:mb-0 flex flex-col gap-y-2.5">
            <h2 className="text-xl">Identificação do Intermediário</h2>
            <p className="text-sm font-light">Maria João Sampaio</p>
            <p className="text-sm font-light">
              Autorizado pelo Banco de Portugal com o nº de registo 0006729
            </p>
            <p className="text-sm font-light">
              Seguro de responsabilidade civil: Apólice nº206731058
            </p>
            <h2 className="text-xl">Categoria de Intermediação de Crédito</h2>
            <p className="text-sm font-light">
              Intermediário vinculado com atividade sujeita à supervisão do
              Banco de Portugal
            </p>
          </div>
        </div>

        <div className="line-break"></div>

        <div className="flex flex-col gap-y-5 md:flex-row md:gap-x-6">
          {/* Second Row */}
          <div className="w-full md:w-1/3  md:px-0">
            <p className="text-xs">
              Resolução alternativa de litígios:{" "}
              <a
                href="https://consumoalgarve.pt/index.php/pt/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                www.consumoalgarve.pt
              </a>
            </p>
          </div>
          <div className="w-full md:w-1/3  md:px-0">
            <p className="text-xs md:text-center">
              <a
                href="https://www.livroreclamacoes.pt/Inicio/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Livro de Reclamações
              </a>
            </p>
          </div>
          <div className="w-full md:w-1/3  md:px-0">
            <p className="text-xs">
              © 2023 | MJS Intermediação de Crédito | Website desenvolvido por{" "}
              <a
                href="https://www.linkedin.com/in/diogoamsguerreiro/"
                target="_blank"
                rel="noreferrer"
                className="underline"
              >
                Diogo Guerreiro
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
