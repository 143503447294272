import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import benefitsImage from "../assets/benefits.png";

const Benefits = () => {
  const [refImage, inViewImage] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const [refContent, inViewContent] = useInView({
    triggerOnce: true,
    threshold: 0.2,
  });

  const imageVariants = {
    hidden: { opacity: 0, x: 100 },
    visible: { opacity: 1, x: 0 },
  };

  const contentVariants = {
    hidden: { opacity: 0, y: 100 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <section
      className="py-24 px-4 md:px-12 gap-y-16 grid benefits-section overflow-hidden"
      id="benefits"
    >
      <div className="container mx-auto flex flex-col lg:items-end lg:justify-between lg:flex-row gap-x-20 gap-y-10 overflow-hidden">
        {/* First Column */}
        <div className="w-full lg:w-1/2 mb-4 lg:mb-0">
          {/* Content for the first column */}
          <span className="uppercase text-base font-light">Vantagens</span>
          <h2>Vantagens de ter um Intermediário de Crédito ao seu lado</h2>
        </div>

        {/* Second Column */}
        <motion.div
          ref={refImage}
          initial="hidden"
          animate={inViewImage ? "visible" : "hidden"}
          variants={imageVariants}
          transition={{ duration: 1, ease: "easeInOut" }}
          className="w-full lg:w-1/2 order-first lg:order-last"
        >
          <img
            src={benefitsImage}
            alt="Beneficios"
            className="bg-cover bg-no-repeat"
          />
        </motion.div>
      </div>
      <motion.div
        ref={refContent}
        initial="hidden"
        animate={inViewContent ? "visible" : "hidden"}
        variants={contentVariants}
        transition={{ duration: 1, ease: "easeInOut" }}
        className="container mx-auto flex flex-col lg:flex-row gap-x-20 gap-y-10"
      >
        {/* First Column */}
        <div className=" grid w-full lg:w-1/3 mb-4 lg:mb-0 gap-y-2.5">
          <ul className="list-disc pl-6">
            <li>
              <h3 className="text-2xl">Aconselhamento Personalizado</h3>
              <p>
                O nosso Intermediário de Crédito fornece orientação
                personalizada, analisando a sua situação financeira e objetivos
                imobiliários. Receba conselhos especializados para tomar
                decisões informadas e seguras. Conte com a nossa expertise para
                alcançar a casa dos seus sonhos.
              </p>
            </li>
          </ul>
        </div>

        {/* Second Column */}
        <div className=" grid w-full lg:w-1/3 mb-4 lg:mb-0 gap-y-2.5">
          <ul className="list-disc pl-6">
            <li>
              <h3 className="text-2xl">Simulamos vários bancos</h3>
              <p>
                Com um Intermediário de Crédito, aceda à nossa rede de
                instituições financeiras, que inclui CGD, Santander, Novo Banco,
                BPI e Bankinter. Oferecemos opções de crédito habitação
                personalizadas e competitivas, garantindo condições favoráveis
                para o seu novo lar. Poupe tempo e encontre a melhor solução com
                a nossa experiência ao seu lado.
              </p>
            </li>
          </ul>
        </div>

        {/* Third Column */}
        <div className=" grid w-full lg:w-1/3 mb-4 lg:mb-0 gap-y-2.5">
          <ul className="list-disc pl-6">
            <li>
              <h3 className="text-2xl">Tratamos de todo o processo</h3>
              <p>
                Com um Intermediário de Crédito a liderar o processo, poupe
                tempo e esforço na obtenção do crédito habitação. Cuide de
                outras prioridades enquanto nós tratamos dos detalhes
                burocráticos e negociações. Desfrute de uma experiência
                tranquila e eficiente rumo à sua casa própria.
              </p>
            </li>
          </ul>
        </div>
      </motion.div>
    </section>
  );
};

export default Benefits;
